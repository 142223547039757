import React, { useState, useEffect } from "react"
import client from '../api/contentful'

import Layout from "../components/layout"
import Container from "../components/Container"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import CtaRightRailAccent from "../components/CtaRightRailAccent"
import TemplateCard from '../components/TemplateCard'

const Templates = () => {
  const[deptContent, setDept] = useState()
  const[marketingContent, setMarketing] = useState()

  useEffect(() => {
    client.getEntries({
        content_type: 'pageLayout',
        'fields.isCollegedept[in]': 'Marketing',
    })
    .then(entry => setMarketing(entry.items))
    .catch(err => console.log(err));

    client.getEntries({
        content_type: 'pageLayout',
        'fields.isCollegedept[in]': 'Dept/College',
    })
    .then(entry => setDept(entry.items))
    .catch(err => console.log(err));
  }, [])

  if (!deptContent || !marketingContent) return null;

  const alphabetizeList = (list) => {
    let newList = list.sort(function(a, b){
      if(a.props.name < b.props.name) { return -1; }
      if(a.props.name > b.props.name) { return 1; }
      return 0;
    })
    return newList
  }

  const marketingTemplatesList = marketingContent.map((item, index)  => {
    return (
        <TemplateCard key={index} slug={`/templates/${item.fields.slug}`} name={item.fields.name}/>
    )
  })

  const deptTemplatesList = deptContent.map((item, index)  => {
    return (
        <TemplateCard key={index} slug={`/templates/${item.fields.slug}`} name={item.fields.name}/>
    )
  })
    
  return (
    <Layout>
      <SEO title="Templates" />
        <Container>
        <section className="page-content col">
            <div className="row">
                <Hero title="Templates" description="See examples and use cases of UTA's Sitecore templates"/>

                <div className="col-lg-9">
                  <h2 className="department">Dept/College</h2>
                  <p className="text-mutd">Templates with navigation and prefooter placeholders</p>
                  <div className="row d-flex">
                    {alphabetizeList(deptTemplatesList)}
                  </div> 

                  <h2 className="mt-5">Marketing</h2>
                  <p className="text-mutd">Templates with either no navigation and prefooter placeholders or optional ones</p>
                  <div className="marketing row d-flex">
                    {alphabetizeList(marketingTemplatesList)}
                  </div>  
                </div>     

                <div className="col-lg-3">
                    <CtaRightRailAccent headline="Find a Component" description="Make unique pages using all the components available to you." path="/components" linkText="View all components"/>
                </div>

            </div>
        </section>
        </Container>
      
    </Layout>
  )
}

export default Templates
